import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql, prefetchPathname } from "gatsby";
import "./reset.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Seo from "../components/Seo/seo";
import GlobalStyles from "../styles/GlobalStyles";
import LogoWhite from "../images/falcon.svg";
import TypographyStyles from "../styles/TypographyStyles";
import animateValue from "./utils";

const Layout = ({ children, blueMenu = false }) => {
  const { pathname } = useLocation();
  const realPathname =
    pathname.length > 1 && pathname.charAt(pathname.length - 1) === "/"
      ? pathname.substring(0, pathname.length - 1)
      : pathname;
  const { site, allSitePage, allJobPages } = useStaticQuery(SEOquery);
  //var blueMenu = false;
  const jobs = allJobPages.edges.length / 2;
  var pathLang;
  const {
    defaultTitle,
    defaultDescription,
    authorSite,
    defaultImage,
  } = site.siteMetadata;

  useEffect(() => {
    const counters = document.querySelectorAll(".counter strong");

    if (counters) {
      for (let item of counters) {
        const no = item.textContent;
        animateValue(item, 0, no, 5000, no > Math.floor(no));
      }
    }
  });

  const seo = {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
    url: `${authorSite}${pathname}`,
  };
  const currentPage = allSitePage.edges.filter(
    (page) => realPathname === page.node.context.pagePath
  );
  //console.log(realPathname);
  if (currentPage.length) {
    const pageContext = currentPage[0].node.context;
    //console.log(pageContext);
    blueMenu = pageContext.blueMenu;
    pathLang = pageContext.pathLang;
    if (pageContext.title) {
      seo.title = pageContext.title + " | " + seo.title;
    }
    if (pageContext.description) {
      seo.description = pageContext.description;
    }
    if (pageContext.image) {
      seo.image = `${authorSite}${pageContext.image}`;
    }
    seo.article = true;
  } else {
    pathLang = pathname.substr(1, 2) === "en" ? "/" : "/en";
  }
  return (
    <>
      <Seo {...seo} />
      <GlobalStyles />
      <TypographyStyles />
      <Navbar
        path={realPathname}
        sitePages={allSitePage.edges}
        jobs={jobs}
        blueMenu={blueMenu}
      />
      <div className="layout">{children}</div>
      <Footer Logo={LogoWhite} path={realPathname} pathLang={pathLang} />
    </>
  );
};

const SEOquery = graphql`
  query layoutSEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        authorSite: authorSite
        defaultImage: image
      }
    }
    allSitePage: allSitePage(
      filter: { context: { title: { ne: null } } }
      sort: { fields: context___order, order: ASC }
    ) {
      edges {
        node {
          context {
            pagePath
            pathLang
            slug
            title
            category
            description
            image
            blueMenu
            locale
            order
          }
        }
      }
    }
    allJobPages: allSitePage(
      filter: { context: { category: { in: "kariera" } } }
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export default Layout;
