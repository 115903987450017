import React from "react";
import styled from "styled-components";

const Button = (props) => {
  return (
    <>
      {props.anchor ? (
        <ButtonWrapper
          aria-label={props.label}
          className="anchor"
          as="a"
          href={props.href}
        >
          {props.cta}
          {props.icon}
        </ButtonWrapper>
      ) : (
        <ButtonWrapper
          aria-label={props.label}
          type={props.type}
          style={
            props.colors
              ? {
                  backgroundColor: props.colors[0].hex,
                  color: props.colors[1].hex,
                }
              : {}
          }
          className={props.icon ? "rounded" : null}
        >
          {props.cta}
          {props.icon}
        </ButtonWrapper>
      )}
    </>
  );
};

const ButtonWrapper = styled.button`
  background-color: #262730;
  color: white;
  cursor: pointer;
  position: relative;
  padding: 0 50px;
  text-align: center;
  line-height: 68px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 14px;
  border: none;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
  &.rounded {
    line-height: 58px;
    border-radius: 29px;
    min-height: 58px !important;
    font-size: 24px;
  }
  img {
    position: relative;
    left: 30px;
    height: 32px;
  }
`;

export default Button;
