import { createGlobalStyle } from "styled-components";

const TypographyStyles = createGlobalStyle`

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 900;
    line-height: 1.4;
  }

  h1,
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;

    @media (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 1.3;
    }

    @media (min-width: 1080px) {
      font-size: 40px;
    }
  }

  p, ul {
    font-size: 1rem;
    line-height: 1.45;
    margin: 1rem 0;
    min-height: 1rem;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    @media (min-width: 1080px) {
      font-size: 20px;
      line-height: 1.5;
    }
  }
  .col-2 {
    column-count: 2;
    column-gap: 35px;
    margin-bottom: 2em;
    p, ul, .block{
      margin-top: 0;
      padding: 1px 0;
      margin-bottom: 1.5;
      break-inside: avoid;
      &:empty{
        break-after: column;
        height: 0px;
        min-height: 0;
        &:after{
          display: inline;
          content: " ";
        }
      }
    }

    @media (max-width: 767px) {
      column-count: auto;
    }
  }
`;
export default TypographyStyles;
