import React from "react";
const Falcon = (props) => {
  return (
    <svg
      width="109"
      height="53"
      viewBox="0 0 109 53"
      fill={props.color ? props.color : "white"}
      xmlns="http://www.w3.org/2000/svg"
      className={props.className ? props.className : ""}
    >
      <path d="M68.7494 1.628C54.6978 -2.75171 46.8508 6.73767 45.5734 14.1826C27.6585 7.81695 8.48785 9.00506 0.498779 10.5328C13.3297 35.7406 37.9089 36.2095 48.037 34.3846C44.4785 38.5819 38.2738 41.5558 33.7116 42.6508C44.3872 55.462 64.8259 56.0095 75.9577 42.7791C68.8406 40.863 64.1872 36.7029 61.8148 34.3846C87.4544 39.3118 104.517 21.1171 108.897 10.5328C90.1918 7.37638 72.1341 11.2968 63.8222 14.1826C63.1835 10.1678 63.9135 5.82523 68.7494 1.628Z" />
    </svg>
  );
};
const Sipka = (props) => {
  return (
    <svg
      width="99"
      height="139"
      viewBox="0 0 99 139"
      fill={props.color ? props.color : "white"}
      xmlns="http://www.w3.org/2000/svg"
      className={props.className ? props.className : ""}
    >
      <g filter="url(#filter0_d)">
        <path d="M67.3122 89.1543C67.62 89.4609 67.6205 89.9592 67.3133 90.2665L50.0555 107.524C49.7486 107.831 49.2511 107.831 48.9443 107.524L31.6855 90.2654C31.3786 89.9586 31.3786 89.4611 31.6855 89.1543L32.6956 88.1441C33.0025 87.8372 33.5001 87.8373 33.8069 88.1443L46.7442 101.088C47.2391 101.584 48.0856 101.233 48.0856 100.533V31.8655C48.0856 31.4316 48.4374 31.0798 48.8713 31.0798H50.1285C50.5624 31.0798 50.9142 31.4316 50.9142 31.8655V100.534C50.9142 101.234 51.7605 101.584 52.2555 101.089L65.1939 88.1508C65.5003 87.8444 65.997 87.8439 66.304 88.1498L67.3122 89.1543Z" />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.455322"
          y="0.079834"
          width="98.0881"
          height="138.675"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="15.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
const SipkaRight = (props) => {
  return (
    <svg
      width="44"
      height="22"
      viewBox="0 0 44 22"
      fill={props.color ? props.color : "white"}
      xmlns="http://www.w3.org/2000/svg"
      className={props.className ? props.className : ""}
    >
      <path
        d="M32.4082 1.30171C32.5741 1.13524 32.8436 1.13499 33.0097 1.30116L42.3432 10.6346C42.5092 10.8006 42.5092 11.0696 42.3432 11.2356L33.0092 20.5696C32.8432 20.7356 32.5742 20.7356 32.4082 20.5696L31.8619 20.0233C31.696 19.8574 31.696 19.5883 31.862 19.4223L38.8625 12.4255C39.1303 12.1578 38.9408 11.7 38.5621 11.7H1.42493C1.19025 11.7 1 11.5098 1 11.2751V10.5952C1 10.3605 1.19025 10.1702 1.42494 10.1702H38.5625C38.9411 10.1702 39.1307 9.71252 38.863 9.44483L31.8655 2.44737C31.6998 2.28164 31.6996 2.01301 31.865 1.84698L32.4082 1.30171Z"
        stroke={props.color ? props.color : "white"}
        stroke-width="2"
      />
    </svg>
  );
};
const Menu = (props) => {
  return (
    <svg
      width="45"
      height="20"
      viewBox="0 0 45 20"
      xmlns="http://www.w3.org/2000/svg"
      fill={props.color ? props.color : "white"}
      className={props.className ? props.className : ""}
    >
      <rect width="45" height="6" rx="3" />
      <rect y="14" width="45" height="6" rx="3" />
    </svg>
  );
};
const Projekty = (props) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      fill={props.color ? props.color : "white"}
      className={props.className ? props.className : ""}
    >
      <circle cx="5" cy="5" r="5" />
      <circle cx="21" cy="5" r="5" />
      <circle cx="5" cy="21" r="5" />
      <circle cx="21" cy="21" r="5" />
    </svg>
  );
};

export { Falcon, Projekty, Menu, Sipka, SipkaRight };
