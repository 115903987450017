import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Link as LinkScroll } from "react-scroll";
import { subpageMenuItems } from "../constants/links";
import styled from "styled-components";
import ProjektyIcon from "../images/projekty.svg";
import MenuIcon from "../images/menu.svg";
import ProjektyIconWhite from "../images/projekty-white.svg";
import MenuIconWhite from "../images/menu-white.svg";
import sipka from "../images/sipka2.svg";
import { Falcon, Projekty, Menu } from "../components/Icons/Icons";
import Close from "../images/close.svg";

const Navbar = ({ Logo, path, sitePages, blueMenu, jobs }) => {
  const [isJobOpen, setJobNav] = useState(false);
  const [isPageOpen, setPageNav] = useState(false);
  const [isMenuSmall, setMenuSmall] = useState(false);
  const headerRef = useRef();
  const toggleNav = (menuType, ev) => {
    ev && ev.preventDefault();
    switch (menuType) {
      case "projekty":
        setPageNav((isPageOpen) => false);
        setJobNav((isJobOpen) => !isJobOpen);
        break;
      case "mainmenu":
      default:
        setJobNav(false);
        setPageNav((isPageOpen) => !isPageOpen);
    }
  };
  const closeNav = (ev) => {
    setPageNav((isPageOpen) => false);
    setJobNav((isJobOpen) => false);
  };
  const _locale = path.substr(1, 2) === "en" ? "en" : "sk";
  //let prevOffset = 0;
  useEffect(() => {
    const handleScroll = () => {
      const distanceY = window.pageYOffset;
      /*
      const firstSectionHeight = document.querySelector(".flex.page")
        ? document.querySelector(".flex.page").firstChild.id !== "mainContent"
          ? document.querySelector(".flex.page").firstChild.clientHeight
          : document.querySelector(".flex.page").firstChild.nextSibling
              .clientHeight
        : document.querySelector(".layout").firstChild.clientHeight;
        */
      if (distanceY > 100) {
        headerRef.current.classList.add("compact");
      } else {
        headerRef.current.classList.remove("compact");
      }
      //console.log(distanceY > prevOffset);
      //prevOffset = distanceY;
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <NavStyles ref={headerRef}>
      <div
        className={isJobOpen || isPageOpen ? "overlay active" : "overlay"}
        onClick={closeNav}
        onKeyDown={closeNav}
      ></div>
      <div className="masthead flex-container">
        <a href={_locale === "sk" ? "/" : "/" + _locale}>
          <Falcon
            color={blueMenu ? "#2243EB" : "white"}
            className="logo-image"
          />
        </a>
        <div>
          <button
            className="toggle-btn"
            type="button"
            onClick={() => toggleNav("projekty")}
            aria-label="Projekty"
          >
            {_locale === "sk" ? (
              <span className={blueMenu ? "hide-sm blue" : "hide-sm"}>
                Projekty
              </span>
            ) : (
              <span className={blueMenu ? "hide-sm blue" : "hide-sm"}>
                Projects
              </span>
            )}
            <Projekty
              color={blueMenu ? "#262730" : "white"}
              className="icon-projekty"
            />
          </button>
          <span className={blueMenu ? "separ blue" : "separ"}></span>
          <button
            className={jobs > 0 ? "toggle-btn lit" : "toggle-btn"}
            type="button"
            onClick={() => toggleNav("mainmenu")}
            aria-label="Menu Button"
          >
            <Menu
              color={blueMenu ? "#262730" : "white"}
              className="icon-menu"
            />
          </button>
        </div>
      </div>
      <ul
        className={isJobOpen ? "nav-links show-nav" : "nav-links"}
        id="projekty"
      >
        <li className="flex">
          {_locale === "sk" ? (
            <Link to="/projekty" onClick={() => toggleNav("projekty")}>
              Projekty
            </Link>
          ) : (
            <Link to="/en/projects" onClick={() => toggleNav("projekty")}>
              Projects
            </Link>
          )}

          <button
            className="toggle-btn"
            type="button"
            onClick={() => toggleNav("projekty")}
            aria-label="Zatvoriť projekty"
          >
            <img src={Close} alt="Close" className="icon-close" />
          </button>
        </li>
        {sitePages
          .filter(
            (item) =>
              item.node.context.category === "projekty" &&
              item.node.context.locale === _locale
          )
          .map((item, index) => {
            return (
              <li
                key={index}
                className={item.node.context.pagePath === path ? "active" : ""}
              >
                <Link
                  to={item.node.context.pagePath}
                  onClick={() => toggleNav("projekty")}
                >
                  <b>{item.node.context.title}</b>
                  <em>{item.node.context.description}</em>
                </Link>
              </li>
            );
          })}
      </ul>
      <ul
        className={isPageOpen ? "nav-links show-nav" : "nav-links"}
        id="mainmenu"
      >
        <li className="flex">
          <Link to="/" onClick={() => toggleNav("mainmenu")}>
            <Falcon color="#2243EB" className="logo-image" />
          </Link>
          <button
            className="toggle-btn"
            type="button"
            onClick={() => toggleNav("mainmenu")}
            aria-label="Zatvoriť menu"
          >
            <img src={Close} alt="Close" className="icon-close" />
          </button>
        </li>
        {sitePages
          .filter(
            (item) =>
              item.node.context.category === null &&
              item.node.context.locale === _locale
          )
          .map((item, index) => {
            return (
              <li
                key={"sitepage_" + index}
                className={item.node.context.pagePath === path ? "active" : ""}
              >
                {item.node.context.slug === "kontakt" ||
                  item.node.context.slug === "contact" ? (
                  <LinkScroll
                    to={"contactForm"}
                    smooth={true}
                    duration={500}
                    className="link"
                    onClick={(ev) => toggleNav("mainmenu", ev)}
                    href={"#contactForm"}
                  >
                    {item.node.context.title}
                  </LinkScroll>
                ) : (
                  <Link
                    to={item.node.context.pagePath}
                    onClick={() => toggleNav("mainmenu")}
                  >
                    {item.node.context.title}
                    {item.node.context.slug === "career" && jobs > 0 && (
                      <span className="hiring">Hiring!</span>
                    )}
                    {item.node.context.slug === "kariera" && jobs > 0 && (
                      <span className="hiring">Prijímame!</span>
                    )}
                  </Link>
                )}
              </li>
            );
          })}
        <li key="spacer" className="spacer"></li>
        {sitePages
          .filter(
            (item) =>
              item.node.context.category === "about" &&
              item.node.context.locale === _locale
          )
          .map((item, index) => {
            return (
              <li
                key={"subpage_" + index}
                className={
                  item.node.context.pagePath === path
                    ? "subpage active"
                    : "subpage"
                }
              >
                <Link
                  to={item.node.context.pagePath}
                  onClick={() => toggleNav("mainmenu")}
                >
                  {item.node.context.title}
                </Link>
              </li>
            );
          })}
        {subpageMenuItems[_locale].map((item, index) => {
          return (
            <li key={"link_" + index} className="subpage">
              <a href={item.path} onClick={() => toggleNav("mainmenu")}>
                {item.text}
              </a>
            </li>
          );
        })}
      </ul>
    </NavStyles>
  );
};

export const NavStyles = styled.nav`
  position: fixed;
  top: 0;
  z-index: 10;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  padding: 50px 80px;
  box-sizing: border-box;
  transition: all 0.3s;
  &:after {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.3s;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
  }
  @media (max-width: 767px) {
    padding: 30px 40px;
  }
  @media (max-width: 400px) {
    padding: 30px 20px 10px;
  }
  .masthead {
    z-index: 2;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    .logo-image {
      width: 80px;
      transition: all 0.3s;
      @media (min-width: 768px) {
        width: 100px;
      }
    }
    .icon-projekty {
      width: 22px;
      vertical-align: middle;
      margin-left: 1em;
      transition: all 0.3s;
    }
    .separ {
      height: 40px;
      width: 1px;
      background-color: #ffffff59;
      display: inline-block;
      vertical-align: middle;
      margin-left: 30px;
      transition: all 0.3s;
      &.blue {
        background-color: #26273059;
      }
    }
    button {
      transition: all 0.3s;
    }
    .icon-menu {
      width: 35px;
      transition: all 0.3s;
      margin-top: 5px;
    }
  }

  &.compact {
    padding: 0 80px 0;
    @media (max-width: 767px) {
      padding: 10px 40px;
    }
    @media (max-width: 400px) {
      padding: 10px 20px 10px;
    }
    &:after {
      opacity: 1;
    }

    .masthead {
      .logo-image {
        width: 60px;
        fill: #2243eb;
        margin-top: 2px;
      }
      button {
        color: #262730;
      }
      .separ {
        background-color: #26273059;
      }
      .icon-projekty,
      .icon-menu {
        fill: #262730;
      }
    }
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    background: white;
    margin: 0;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    width: 470px;
    max-width: 100%;
    overflow: auto;
    transform: translateX(100%);
    transition: 0.3s ease-in;
    list-style: none;
    padding-left: 0;
    z-index: 3;
    li {
      list-style: none;
      padding-left: 30px;
      cursor: pointer;
      background-color: white;
      &.flex {
        padding: 0px 30px;
        justify-content: space-between;
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        align-items: center;
        flex-direction: row;
        .toggle-btn {
          margin-right: 30px;
          img {
            width: 24px;
          }
        }
        a {
          color: #26273099;
          font-weight: bold;
          font-size: 18px;
          .logo-image {
            width: 80px;
          }
        }
      }
      a {
        text-decoration: none;
        color: #2243eb;
        font-size: 30px;
        line-height: 1.5;
        font-weight: bold;
        transition: 0.3s;
        display: inline-block;
        padding-left: 30px;
        .hiring {
          background-color: #3ddc97;
          text-transform: uppercase;
          color: white;
          border-radius: 20px;
          overflow: hidden;
          margin-left: 10px;
          font-size: 13px;
          line-height: 30px;
          padding: 0px 10px;
          letter-spacing: 2px;
          display: inline-block;
          text-indent: 0;
          vertical-align: middle;
        }
      }

      &.active,
      &:hover {
        a {
          color: #eb2252;
        }
      }
      &.spacer {
        height: 2em;
      }
      &.subpage {
        a {
          color: #2627304d;
          font-weight: 500;
          font-size: 24px;
        }
        &.active,
        &:hover {
          a {
            color: #262730;
          }
        }
      }
    }

    &.show-nav {
      transform: translateX(0%);
    }
    &#projekty li:not(.flex) {
      border-radius: 24px;
      margin: 10px 30px 0;
      text-align: left;
      position: relative;
      transition: all 0.3s;
      padding-left: 0;
      a {
        padding: 15px 30px 20px;
        text-indent: 0;
        font-size: 14px;
        line-height: 1.5em;
        b {
          display: block;
          font-size: 30px;
          line-height: 1.5;
        }
        em {
          font-style: normal;
          display: block;
          color: #26273055;
        }
      }
      &:after {
        background-image: url(${sipka});
        width: 44px;
        height: 22px;
        position: absolute;
        content: "";
        right: 24px;
        top: 24px;
      }
      &.active,
      &:hover {
        background-color: #eb2252;
        a,
        em {
          color: white;
        }
      }
    }

    @media (max-height: 760px) {
      padding-top: 100px;
      padding-bottom: 40px;

      &#projekty {
        em {
          display: none !important;
        }
      }
    }
  }

  .toggle-btn {
    padding: 5px 0 5px 30px;
    background-color: transparent;
    border: none;
    vertical-align: middle;
    color: white;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    position: relative;
    .blue {
      color: #262730;
    }
    &.lit:after {
      display: block;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #3ddc97;
      position: absolute;
      top: 0px;
      right: -7px;
      transition: all 0.3s;
      animation: pulse 3s infinite;
    }
  }

  @keyframes pulse {
    0%,
    80%,
    100% {
      opacity: 1;
    }

    90% {
      opacity: 0.3;
    }
  }
`;

export default Navbar;
