export const subpageMenuItems = {
  sk: [
    {
      path: "https://www.facebook.com/JUMPsoft/",
      text: "Facebook",
    },
    {
      path: "https://sk.linkedin.com/company/jump-soft",
      text: "LinkedIn",
    },
  ],
  en: [
    {
      path: "https://www.facebook.com/JUMPsoft/",
      text: "Facebook",
    },
    {
      path: "https://sk.linkedin.com/company/jump-soft",
      text: "LinkedIn",
    },
  ],
};
