import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const SEO = (props) => {
  return (
    <Helmet title={props.title}>
      <meta name="description" content={props.description} />
      <meta name="image" content={props.image} />
      {props.url && <meta property="og:url" content={props.url} />}
      {(props.article ? true : null) && (
        <meta property="og:type" content="article" />
      )}
      {props.title && <meta property="og:title" content={props.title} />}
      {props.description && (
        <meta property="og:description" content={props.description} />
      )}
      {props.image && <meta property="og:image" content={props.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {props.title && <meta name="twitter:title" content={props.title} />}
      {props.description && (
        <meta name="twitter:description" content={props.description} />
      )}
      {props.image && <meta name="twitter:image" content={props.image} />}
      {/*<script src="https://www.google.com/recaptcha/api.js?render=6Lep6W0bAAAAAH_8kyHQaZmZrGDJzF9FIjx0-_oX" />*/}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2243eb" />
      <meta name="msapplication-TileColor" content="#2243eb" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  lang: `sk`,
  article: false,
};
