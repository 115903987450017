import React, { useState, useCallback } from "react";
import { Link } from "gatsby";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import axios from "axios";
import Button from "../Button/button";
import PropTypes from "prop-types";
import unchecked from "../../images/unchecked.svg";
import checked from "../../images/checked.svg";

const ContactForm = ({ locale, path, isJob, hasAttachment }) => {
  //var grecaptcha = null;
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    /*if (ok) {
      form.reset();
      form.style.display = "none";
      HTMLCollection.prototype.forEach = Array.prototype.forEach;

      var list = document.getElementsByClassName("checked");
      while (list.length) list[0].classList.remove("checked");
      document.getElementById("fileUpload").classList.add("placeholder");
      document.getElementById("fileUpload").innerHTML =
        locale === "sk"
          ? isJob
            ? "Sem presuňte CV"
            : "Sem presuňte prílohu"
          : isJob
          ? "Here drag your CV"
          : "Drag an attachment here";
    }*/
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    if (document.getElementById("city").value.length) {
      handleServerResponse(
        true,
        locale === "sk" ? "Vyzerá to že si bot!" : "We think you are a bot!",
        form
      );
    } else {
      form.style.opacity = 0.5;
      axios({
        method: "post",
        url: "https://getform.io/f/8b34f011-52e7-44f9-b6a3-714b5283c756",
        data: new FormData(form),
      }).then((r) => {
        handleServerResponse(
          true,
          locale === "sk" ? "Ďakujeme!" : "Thanks!",
          form
        );
      });
      /* .catch((r) => {
          handleServerResponse(
            false,
            locale === "sk"
              ? "Správu sa nepodarilo odoslať!"
              : "Message couldn't been sent!",
            form
          );
        });*/
    }
    /* if (window.grecaptcha) {
      grecaptcha = window.grecaptcha;
      grecaptcha.ready(() => {
        grecaptcha
          .execute("6Lep6W0bAAAAAH_8kyHQaZmZrGDJzF9FIjx0-_oX", {
            action: "homepage",
          })
          .then((token) => {
            document.getElementById("captchaResponse").value = token;
          })
          .then(() => {
            axios({
              method: "post",
              url: "https://getform.io/f/621e3331-0067-479c-b639-c1b2a4b26330",
              data: new FormData(form),
            })
              .then((r) => {
                handleServerResponse(
                  true,
                  locale === "sk" ? "Ďakujeme!" : "Thanks!",
                  form
                );
              })
              .catch((r) => {
                handleServerResponse(false, r.response.data.error, form);
              });
          });
      });
    }*/
  };
  const maxSize = 5048576;
  const btnColors = [{ hex: "#eb2252" }, { hex: "#FFFFFF" }];
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      document.getElementById("fileUpload").classList.remove("placeholder");
    } else {
      document.getElementById("fileUpload").classList.add("placeholder");
    }
  }, []);
  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept:
      "image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/html, image/jpeg, application/pdf, application/rtf, text/plain, application/vnd.ms-excel, application/zip, application/x-7z-compressed, application/vnd.rar, application/vnd.ms-powerpoint, application/gzip",
    minSize: 0,
    maxSize,
  });

  const isFileTooLarge =
    rejectedFiles &&
    rejectedFiles.length > 0 &&
    rejectedFiles[0].size > maxSize;

  const setCustomValidity = (e, msg) => {
    e.target.validity.valid
      ? e.target.setCustomValidity("")
      : e.target.setCustomValidity(msg);
  };
  const toggleCheck = (e) => {
    var el = e.target.closest("label");
    el.tagName === "LABEL" &&
      (el.classList.contains("checked")
        ? el.classList.remove("checked")
        : el.classList.add("checked"));
  };
  return (
    <ContactWrapper id="contact">
      <div className="content-container">
        {locale === "sk" ? (
          <>
            <h2>
              {serverState.status ? serverState.status.msg : "Kontaktujte nás"}
            </h2>
            <p
              style={{
                display:
                  serverState.status && serverState.status.ok
                    ? "block"
                    : "none",
              }}
            >
              Správa bola odoslaná.
            </p>
          </>
        ) : (
          <>
            <h2>
              {serverState.status ? serverState.status.msg : "Contact us"}
            </h2>
            <p
              style={{
                display:
                  serverState.status && serverState.status.ok
                    ? "block"
                    : "none",
              }}
            >
              Your message was sent.
            </p>
          </>
        )}
        {locale === "sk" ? (
          <form
            name="contact"
            onSubmit={handleOnSubmit}
            encType="multipart/form-data"
            style={{
              display: !(serverState.status && serverState.status.ok)
                ? "block"
                : "none",
            }}
          >
            <input
              type="hidden"
              value={typeof window !== "undefined" ? window.location.href : ""}
              name="form url"
            />

            <div className="flex">
              <div className="col">
                <div className="input-area alt">
                  <input
                    type="text"
                    name="city"
                    aria-label="city"
                    autoComplete="off"
                    placeholder="City"
                    id="city"
                  />
                  <label className="label-name" htmlFor="city">
                    <span className="content-name">Moje mesto je</span>
                  </label>
                </div>
                <div className="input-area required">
                  <input
                    type="text"
                    name="name"
                    aria-label="Meno"
                    required
                    autoComplete="off"
                    placeholder="Vaše meno"
                    //onInvalid={(e) => setCustomValidity(e, "Napíšte Vaše meno prosím") }
                  />
                  <label className="label-name" htmlFor="name">
                    <span className="content-name">Moje meno je</span>
                  </label>
                </div>

                <div className="input-area">
                  <input
                    type="tel"
                    name="phone"
                    aria-label="Telefón"
                    autoComplete="off"
                    placeholder="Váš telefón"
                  />
                  <label className="label-name" htmlFor="phone">
                    <span className="content-name">
                      Moje telefónne číslo je
                    </span>
                  </label>
                </div>

                <div className="input-area">
                  <input
                    type="text"
                    name="company"
                    aria-label="Spoločnosť"
                    placeholder="Vaša spoločnosť"
                    autoComplete="off"
                  />
                  <label className="label-name" htmlFor="company">
                    <span className="content-name">Pracujem v spoločnosti</span>
                  </label>
                </div>

                <div className="input-area required">
                  <input
                    type="email"
                    name="email"
                    aria-label="Email"
                    required
                    autoComplete="off"
                    placeholder="Váš email"
                    //onInvalid={(e) => setCustomValidity(e, "Zadajte Váš email prosím")}
                  />
                  <label className="label-name" htmlFor="email">
                    <span className="content-name">Môžete mi napísať na</span>
                  </label>
                </div>
              </div>
              <div className="col">
                {(isJob || hasAttachment) && (
                  <div className="input-area" {...getRootProps()}>
                    <div
                      id="fileUpload"
                      className={
                        "input" +
                        (isFileTooLarge || isDragReject
                          ? " error"
                          : isDragActive
                          ? " active"
                          : " placeholder")
                      }
                    >
                      {isDragActive && !isDragReject
                        ? "Nahrať tento súbor"
                        : isDragReject
                        ? "Nevhodný typ súboru"
                        : isFileTooLarge
                        ? "Limit max 2MB"
                        : acceptedFiles.length > 0
                        ? acceptedFiles.length > 0 &&
                          acceptedFiles.map((acceptedFile) => acceptedFile.name)
                        : isJob
                        ? "Sem presuňte CV"
                        : "Sem presuňte prílohu"}
                    </div>
                    <input
                      {...getInputProps()}
                      name="attachment"
                      //required={isJob ? true : false}
                    />
                    <label className="label-name " htmlFor="attachment">
                      <span className="content-name">
                        {isJob ? "Tu je moje CV" : "Príloha"}
                      </span>
                    </label>
                  </div>
                )}
                <div className="input-area required">
                  <textarea
                    type="text"
                    name="message"
                    rows="4"
                    required
                    autoComplete="off"
                    aria-label="Správa"
                    placeholder="Začnite písať sem…"
                    //onInvalid={(e) => setCustomValidity(e, "Napíšte nám niečo")}
                  />
                  <label className="label-name" htmlFor="message">
                    <span className="content-name">Moja správa</span>
                  </label>
                </div>
              </div>
            </div>

            {isJob && (
              <>
                <div className="input-area required checkbox">
                  <label className="label-name">
                    <span className="input">
                      <input
                        type="checkbox"
                        name="accept_gdpr"
                        onChange={(e) => toggleCheck(e)}
                        required
                        value="Vyhlasujem, že som sa oboznámil so Zásadami spracúvania osobných údajov spoločnosti JUMP soft a.s. ako aj s Poučením uchádzača o zamestnanie. Beriem na vedomie, že osobné údaje poskytnuté za účelom výberového konania na zamestnanca spoločnosti budú uchovávané do ukončenia výberového konania, avšak najviac 3 mesiace, ak nedohodnuté inak."
                      />
                      <span className="checkmark">&nbsp;</span>
                    </span>
                    <span className="label-text">
                      Vyhlasujem, že som sa oboznámil so{" "}
                      <Link to="/legal/ochrana-osobnych-udajov">
                        Zásadami spracúvania osobných údajov spoločnosti
                      </Link>{" "}
                      JUMP soft a.s. ako aj s{" "}
                      <Link to="/legal/uchadzaci">
                        Poučením uchádzača o zamestnanie
                      </Link>
                      . Beriem na vedomie, že osobné údaje poskytnuté za účelom
                      výberového konania na zamestnanca spoločnosti budú
                      uchovávané do ukončenia výberového konania, avšak najviac
                      3 mesiace, ak nedohodnuté inak.
                    </span>
                  </label>
                </div>
                <div className="input-area checkbox">
                  <label className="label-name">
                    <span className="input">
                      <input
                        type="checkbox"
                        name="accept_CV"
                        onChange={(e) => toggleCheck(e)}
                        value="Súhlasím so zaradením môjho životopisu a jeho príloh do databázy uchádzačov o zamestnanie vedeného spoločnosťou JUMP soft, a.s. za účelom výberového konania v prípade uvoľnenia pracovnej pozície. Súhlas je dobrovoľný a môžete ho kedykoľvek odvolať žiadosťou na email: gdpr@jump-soft.com.  Súhlas sa udeľuje na obdobie 3 rokov. Po tomto období budete z databázy automaticky vyradený a Vaše údaje vymazané."
                      />
                      <span className="checkmark">&nbsp;</span>
                    </span>
                    <span className="label-text">
                      Súhlasím so zaradením môjho životopisu a jeho príloh do
                      databázy uchádzačov o zamestnanie vedeného spoločnosťou
                      JUMP soft, a.s. za účelom výberového konania v prípade
                      uvoľnenia pracovnej pozície. Súhlas je dobrovoľný a môžete
                      ho kedykoľvek odvolať žiadosťou na email:
                      gdpr@jump-soft.com.  Súhlas sa udeľuje na obdobie 3 rokov.
                      Po tomto období budete z databázy automaticky vyradený
                      a Vaše údaje vymazané.
                    </span>
                  </label>
                </div>
              </>
            )}
            {!isJob && (
              <div className="input-area required checkbox">
                <label className="label-name" onClick={(e) => toggleCheck(e)}>
                  <span className="input">
                    <input
                      type="checkbox"
                      name="accept_gdpr"
                      required
                      value="Súhlasím so Zásadami spracúvania osobných údajov spoločnosti JUMP soft a.s."
                    />
                    <span className="checkmark">&nbsp;</span>
                  </span>
                  <span className="label-text">
                    Súhlasím so{" "}
                    <Link to="/legal/ochrana-osobnych-udajov">
                      Zásadami spracúvania osobných údajov spoločnosti
                    </Link>{" "}
                    JUMP soft a.s.
                  </span>
                </label>
              </div>
            )}
            <div className="input-area button-area">
              <Button
                label="Send Contact Form"
                cta="ODOSLAŤ"
                type="submit"
                tabIndex="-1"
                colors={btnColors}
              />
            </div>
          </form>
        ) : (
          <form
            name="contact"
            onSubmit={handleOnSubmit}
            encType="multipart/form-data"
            style={{
              display: !(serverState.status && serverState.status.ok)
                ? "block"
                : "none",
            }}
          >
            <input
              type="hidden"
              value={typeof window !== "undefined" ? window.location.href : ""}
              name="form url"
            />
            <div className="flex">
              <div className="col">
                <div className="input-area alt">
                  <input
                    type="text"
                    name="city"
                    aria-label="city"
                    autoComplete="off"
                    placeholder="City"
                    id="city"
                  />
                  <label className="label-name" htmlFor="city">
                    <span className="content-name">My city is</span>
                  </label>
                </div>
                <div className="input-area">
                  <input
                    type="text"
                    name="name"
                    aria-label="Name"
                    required
                    autoComplete="off"
                    placeholder="Your name"
                  />
                  <label className="label-name" htmlFor="name">
                    <span className="content-name">My name is</span>
                  </label>
                </div>

                <div className="input-area">
                  <input
                    type="tel"
                    name="phone"
                    aria-label="Phone"
                    autoComplete="off"
                    placeholder="Your phone"
                  />
                  <label className="label-name" htmlFor="phone">
                    <span className="content-name">My phone number is</span>
                  </label>
                </div>

                <div className="input-area">
                  <input
                    type="text"
                    name="company"
                    aria-label="Company"
                    placeholder="Your company"
                    autoComplete="off"
                  />
                  <label className="label-name" htmlFor="company">
                    <span className="content-name">I do work for</span>
                  </label>
                </div>

                <div className="input-area">
                  <input
                    type="email"
                    name="email"
                    aria-label="Email"
                    required
                    autoComplete="off"
                    placeholder="Your email"
                  />
                  <label className="label-name" htmlFor="email">
                    <span className="content-name">You can write me at</span>
                  </label>
                </div>
              </div>
              <div className="col">
                {(isJob || hasAttachment) && (
                  <div className="input-area" {...getRootProps()}>
                    <div
                      id="fileUpload"
                      className={
                        "input" +
                        (isFileTooLarge || isDragReject
                          ? " error"
                          : isDragActive
                          ? " active"
                          : " placeholder")
                      }
                    >
                      {isDragActive && !isDragReject
                        ? "Upload this file"
                        : isDragReject
                        ? "Unknown filetype"
                        : isFileTooLarge
                        ? "Limit max 2MB"
                        : acceptedFiles.length > 0
                        ? acceptedFiles.length > 0 &&
                          acceptedFiles.map((acceptedFile) => acceptedFile.name)
                        : isJob
                        ? "Here drag your CV"
                        : "Drag an attachment here"}
                    </div>
                    <input
                      {...getInputProps()}
                      // required={isJob ? true : false}
                      name="attachment"
                    />
                    <label className="label-name" htmlFor="attachment">
                      <span className="content-name">
                        {isJob ? "Here is my CV" : "Attach file"}
                      </span>
                    </label>
                  </div>
                )}

                <div className="input-area">
                  <textarea
                    type="text"
                    name="message"
                    rows="4"
                    required
                    autoComplete="off"
                    aria-label="Message"
                    placeholder="Start your message here…"
                  />
                  <label className="label-name" htmlFor="message">
                    <span className="content-name">My message</span>
                  </label>
                </div>
              </div>
            </div>
            {isJob && (
              <>
                <div className="input-area required checkbox">
                  <label className="label-name">
                    <span className="input">
                      <input
                        type="checkbox"
                        name="accept_gdpr"
                        onChange={(e) => toggleCheck(e)}
                        required
                        value="I declare that I have read the Principles of Personal Data Processing of JUMP soft a.s. as well as with the Job Applicant's Instruction. I acknowledge that personal data provided for the purpose of the selection procedure per employee of the company will be kept until the end of the selection procedure, but for a maximum of 3 months, unless otherwise agreed"
                      />
                      <span className="checkmark">&nbsp;</span>
                    </span>
                    <span className="label-text">
                      I declare that I have read the{" "}
                      <Link to="/legal/ochrana-osobnych-udajov">
                        Principles of Personal Data Processing
                      </Link>{" "}
                      of JUMP soft a.s. as well as with the{" "}
                      <Link to="/legal/uchadzaci">
                        Job Applicant's Instruction
                      </Link>
                      . I acknowledge that personal data provided for the
                      purpose of the selection procedure per employee of the
                      company will be kept until the end of the selection
                      procedure, but for a maximum of 3 months, unless otherwise
                      agreed.
                    </span>
                  </label>
                </div>
                <div className="input-area checkbox">
                  <label className="label-name">
                    <span className="input">
                      <input
                        type="checkbox"
                        name="accept_CV"
                        onChange={(e) => toggleCheck(e)}
                        value="I agree with the inclusion of my CV and its appendices in the database of job seekers maintained by JUMP soft, a.s. for the purpose of selection procedure in the event of vacancy. Consent is voluntary and can be revoked at any time by requesting email: gdpr@jump-soft.com. Consent is granted for a period of 3 years. After this period, you will be automatically removed from the database and your data deleted."
                      />
                      <span className="checkmark">&nbsp;</span>
                    </span>
                    <span className="label-text">
                      I agree with the inclusion of my CV and its appendices in
                      the database of job seekers maintained by JUMP soft, a.s.
                      for the purpose of selection procedure in the event of
                      vacancy. Consent is voluntary and can be revoked at any
                      time by requesting email: gdpr@jump-soft.com. Consent is
                      granted for a period of 3 years. After this period, you
                      will be automatically removed from the database and your
                      data deleted.
                    </span>
                  </label>
                </div>
              </>
            )}
            {!isJob && (
              <div className="input-area required checkbox">
                <label className="label-name" onClick={(e) => toggleCheck(e)}>
                  <span className="input">
                    <input
                      type="checkbox"
                      name="accept_gdpr"
                      required
                      value="I agree with the Principles of Personal Data Processing of JUMP soft a.s."
                    />
                    <span className="checkmark">&nbsp;</span>
                  </span>
                  <span className="label-text">
                    I agree with the{" "}
                    <Link to="/legal/ochrana-osobnych-udajov">
                      Principles of Personal Data Processing
                    </Link>{" "}
                    JUMP soft a.s.
                  </span>
                </label>
              </div>
            )}
            <div className="input-area button-area">
              <Button
                label="Send Contact Form"
                cta="SEND"
                type="submit"
                tabIndex="-1"
                colors={btnColors}
              />
            </div>
          </form>
        )}
      </div>
    </ContactWrapper>
  );
};
ContactForm.propTypes = {
  locale: PropTypes.string,
  path: PropTypes.string,
};
const ContactWrapper = styled.section`
  .content-container {
    width: 100%;
    margin: 0 auto;
    max-width: 100%;

    h2 {
      text-align: left;
      margin-bottom: 45px;
    }
    p {
      margin-bottom: 2rem;

      @media (max-width: 767px) {
        text-align: center;
      }
    }
    .alt {
      display: none;
    }
    form {
      position: relative;
      transition: all 0.3s;
      .input-area {
        margin-bottom: 25px;
        position: relative;

        &.button-area {
          margin-bottom: 0;
        }
      }
      input:-webkit-autofill {
        -webkit-text-fill-color: #54457f !important;
      }
      input,
      .input,
      textarea {
        height: 100%;
        font-size: 30px;
        padding: 20px 0;
        display: block;
        width: 100% !important;
        border: none;
        color: #262730;
        position: relative;
        box-sizing: border-box;
        outline: none;
        line-height: 1.15em;
        box-shadow: 0 0 0 60px rgb(244 244 244) inset !important;
        background-color: transparent;

        &[name="attachment"] {
          position: absolute;
          opacity: 0;
          top: 0;
          pointer-events: none;
          display: block !important;
          z-index: -1;
        }
        &:placeholder-shown {
          + label .content-name {
            color: #262730;
          }
        }
        &[required] {
          + label .content-name {
            /* color: #2243eb;*/
          }
          + label .content-name:after {
            color: #2243eb;
            display: inline-block;
            content: "*";
            margin-left: 5px;
          }
        }
        &:valid {
          + label .content-name {
            color: rgba(0, 0, 0, 0.2);
          }
        }
      }
      #fileUpload {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      label {
        position: absolute;
        top: -34px;
        left: 0px;
        width: 100%;
        height: 100%;
        pointer-events: none;
        &::after {
          content: "";
          position: absolute;
          left: 0px;
          bottom: -1px;
          height: 1px;
          width: 100%;
          transform: translateX(-100%);
          transition: transform 0.3s ease;
        }
      }
      .checkbox {
        label {
          position: static;
          display: flex;
          pointer-events: all;
          align-items: center;
          padding-right: 20px;
        }

        &.required .label-text:before {
          color: #2243eb;
          display: inline-block;
          content: "*";
          margin-right: 2px;
          font-size: 20px;
          line-height: 10px;
          position: relative;
          top: 5px;
        }
        .input {
          pointer-events: none;
          width: 24px !important;
          margin-right: 20px;
          input {
            height: 24px !important;
            flex-shrink: 0;
            background-color: transparent;
            appearance: none;
            position: absolute;
            padding: 0;
            width: 24px !important;
            box-shadow: none !important;
          }
        }
        .checkmark {
          background: url(${unchecked});
          height: 24px;
          width: 24px !important;
          margin-right: 20px;
          display: block;
        }
        input:checked + .checkmark {
          background: url(${checked});
        }
      }
      .content-name {
        position: absolute;
        top: 20px;
        left: 0px;
        transition: all 0.3s ease;
        font-size: 20px;
        &.placeholder {
          color: #262730 !important;
        }
      }
      .flex {
        .col {
          flex: 1;
          @media (min-width: 1201px) {
            max-width: 50%;
          }
        }
      }
    }
  }
`;

export default ContactForm;
