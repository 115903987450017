const animateValue = (obj, start, end, duration, decimal) => {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    const progressNo = decimal
      ? (progress * (end - start) + start).toFixed(2)
      : Math.floor(progress * (end - start) + start);
    obj.innerHTML = progressNo;
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};

export default animateValue;
