import React from "react";
import { useStaticQuery, Link, graphql } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";
import ContactForm from "./Contact/contactForm"

const Footer = ({ Logo, path, pathLang }) => {
  const _locale = path.substr(1, 2) === "en" ? "en" : "sk";
  const isJob = () => {
    const _segments = path.split("/")
    let _found = false
    _segments.forEach(element => {
      if (element === "kariera" || element === "career") {
        _found = true
      }
    });
    return _found
  }
  const hasAttachment = () => {
    const _segments = path.split("/")
    let _found = false
    _segments.forEach(element => {
      if (element === "legal") {
        _found = true
      }
    });
    //console.log("hasAttachment: " + _found)
    return _found
  }
  const data = useStaticQuery(
    graphql`
      query {
        sk: graphCmsPage(slug: { eq: "kontakt" }, locale: { eq: sk }) {
          body {
            html
          }
        }
        en: graphCmsPage(slug: { eq: "contact" }, locale: { eq: en }) {
          body {
            html
          }
        }
      }
    `
  );

  const footerData = data[_locale];

  return (
    <FooterStyles>
      <div className="flex">
        <div id="contactForm">
          <ContactForm locale={_locale} path={path} isJob={isJob()} hasAttachment={hasAttachment()} />
        </div>
        <div id="footerContact">
          <div dangerouslySetInnerHTML={{ __html: footerData.body.html }} />
          <p><Link to={pathLang}>{_locale === "sk" ? "English version" : "Slovenská verzia"}</Link></p>
        </div>
      </div>
    </FooterStyles>
  );
};

Footer.propTypes = {
  Logo: PropTypes.string,
  path: PropTypes.string,
};

const FooterStyles = styled.footer`
  #contactForm{
    flex: 2;
    padding: 100px 0 100px 0px;
    max-width: 930px;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .flex{
      @media (max-width: 1200px){
        flex-direction: column;
        align-items: stretch;
      }
    }
    @media (max-width: 1360px){
      padding-left: 30px;
    }
    @media (max-width: 960px){
      padding: 30px;
    }
  }
  .flex{
    justify-content: flex-end;
    background-color: #f4f4f4;
      @media (max-width: 960px){
        flex-direction: column;
        align-items: stretch;
      }
  }
  #footerContact{
    flex: 1;
    padding: 100px;
    background-color: #262730;
    color: rgba(255,255,255,.3);
    max-width: 33%;
    min-width: 500px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
      @media (max-width: 991px){
        max-width: 100%;
        min-width: 100px;
        padding: 60px;
      }
    a{
      color: #EB2252;
      text-decoration: none;
    }
    strong{
      color: white;
    }
    p{
      font-size: 18px;
    }
  }
`;

export default Footer;
